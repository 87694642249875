$header-height: 76px;
$sidebar-width: 180px;
$mobile-sidebar-width: 250px;

.navbar {
    background-color: white;
    position: fixed;
    height: $header-height;
    z-index: 1020;
    width: 100%;
    flex: 0 0 $header-height;
}

.app-body {
    // making this flex breaks responsive tables. not sure if we need it for something.
    // display: flex;
    // flex-direction: row;
    // flex-grow: 1;
    overflow-x: hidden;
    margin-top: $header-height;
}

.sidebar {
    display: flex;
    flex-direction: column;
    flex: 0 0 $sidebar-width;
    position: fixed;
    z-index: 1019;
    width: $sidebar-width;
    height: calc(100vh - #{$header-height});
    padding-top: 1rem;
}

.main {
    margin-left: $sidebar-width;
    &.externalUser {
        margin-left: 0;
    }
    // flex: 1;
    padding: 0 $main-gutter-width;
    // min-height: calc(100vh - #{$header-height});
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.sidebar .sidebar-nav {
    position: relative;
    flex: 1;
    // overflow-x: hidden;
    // overflow-y: auto;
}

.sidebar-nav {
    transition: width 0.25s;
}

.sidebar .nav {
    flex-direction: column;
    min-height: 100%;
}

.sidebar .nav-title {
    padding: 1.375rem 1.25rem 0.5rem 1.75rem;
}

.sidebar .nav-item {
    position: relative;
    margin: 0;
    transition: background 0.3s ease-in-out;
}

.sidebar .nav-link {
    display: block;
    font-size: rem(15);
    padding: 0.75rem 1.5rem;

    .svg-inline--fa {
        margin: 0 0.465rem 0 0;
        font-size: rem(15);
        text-align: center;
    }
}

.sidebar,
.main {
    transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

@include media-breakpoint-down(md) {
    .main {
        margin-left: 0;
    }

    .sidebar {
        margin-left: -$mobile-sidebar-width;
    }

    .sidebar-mobile-show {
        .sidebar {
            width: $mobile-sidebar-width;
            margin-left: 0;
        }

        .main {
            margin-right: -$mobile-sidebar-width !important;
            margin-left: $mobile-sidebar-width !important;
        }
    }
}
