:root {
  --action-anchor: #54819f;
  --border-gray: #dee2e6;
  --light-gray-bg: #f6f7f9;
  --icon-light-gray: #bdc3c7;
  --orange-bright: #ff7f2a;
  --playbook-pewter: #98a1b1;
  --schedule-sapphire: #008be7;
  --schedule-sapphire-focus: #008be7e0;
  --navy-blue: #004c99;
  --champion-charcoal: #121a23;
  --goal-gray: #e4e9ee;
  --green-bright: #1db082;
  --danger: #ff4a52;
  --purple-bright: #7773fc;
  --yellow-bright: #ffc43a;
  --scope-sky: linear-gradient(to right, #4dc9fe, #2585fd);
  --standard-snow: #f9fafb;
  --white: #fff;
  --implement-iron: #536b79;
  --box-shadow-menu: 0px 2px 10px rgba(0, 0, 0, 0.3);
  --field-value-shadow: 0 0.25rem 0.125rem -0.125rem rgb(0 139 231 / 25%);
  --outline: #2684ff;
  --input-disabled: hsl(0, 0%, 60%);
  --input-enabled: hsl(0, 0%, 20%);
  --read-only: #e9ecef;
  --table-row-hover: #f8f9fa;
  --modal-scrollbar: rgba(0, 139, 231, 0.25);
  --pumpkin: #fa7820;
  --rich-electric-blue: #0d9dda;
  --join-jade-1: #00e490;
  --marketing-gradient: linear-gradient(90deg, #2471ff 0%, #1cc6e7 63%, #1ae5dc 98%);
  --whisper: #ebebeb;
  --sunglow: #ffc43a;
  --pool-water: #2585fd;
  --dodger-blue: #2471ff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.comments-section {
  max-height: 320px;
  overflow-y: auto;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  padding-right: 20px;
}

.comments-section::-webkit-scrollbar {
  width: 8px;
}

.comments-section::-webkit-scrollbar-track {
  background: transparent;
}

.comments-section::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

input.email-field:disabled {
  background: #fff;
  opacity: 0.5;
  cursor: not-allowed;
}

select.email-field:disabled {
  background: var(--light-gray-bg);
  opacity: 0.9;
  cursor: not-allowed;
}

.tox-editor-container {
  z-index: 0 !important;
}
