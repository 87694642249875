.Toastify .svg-inline--fa {
  color: white;
}

.app .Toastify__toast {
  border-radius: 4px;
  padding-left: 12px;
}

.app .Toastify__toast--error {
  background: $danger;
}

.app .Toastify__toast--success {
  background: $success;
}

.app .Toastify__toast--info {
  background: $implement-iron;
}

.Toastify__close-button {
  margin-left: 5px;
}