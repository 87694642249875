$champion-charcoal: #121a23;
// $black: #2F4960; // scott's black?
$black: $champion-charcoal;
$playbook-pewter: #98a1b1;
$white: #fff;
$text-muted: $playbook-pewter;
$action-anchor: #54819f;
$schedule-sapphire: #008be7;
$goal-gray: #e4e9ee;
$light-gray-bg: #f6f7f9;
$gray-100: #f8f9fa;
$standard-snow: #f9fafb;
$hover: $gray-100;
// $hover: $goal-gray;
$implement-iron: #536b79;
$border-gray: #EBEBEB;
$icon-light-gray: #bdc3c7;
$circle-icon-gray: #f0f4fb;
$danger: #ff4a52;
$success: #28a745;
$scope-sky-1: #4dc9fe;
$scope-sky-2: #2585fd;
$scope-sky-1-hover: darken($scope-sky-1, 7.5%);
$scope-sky-2-hover: darken($scope-sky-2, 7.5%);
$scope-sky: linear-gradient(90deg, $scope-sky-1 0%, $scope-sky-2 100%);
$scope-sky-hover: linear-gradient(90deg, $scope-sky-1-hover 0%, $scope-sky-2-hover 100%);
$join-jade-1: #00e490;
$join-jade-2: #00bdb5;
$join-jade: linear-gradient(90deg, $join-jade-1 0%, $join-jade-2 100%);
$process-peach-1: #ffdd72;
$process-peach-2: #ff7c7c;
$process-peach: linear-gradient(90deg, $process-peach-1 0%, $process-peach-2 100%);
$retention-rose-1: #ff85ca;
$retention-rose-2: #fe317d;
$retention-rose: linear-gradient(90deg, $retention-rose-1 0%, $retention-rose-2 100%);
$module-mist: #c6dbf2;
$orange-bright: #ff7f2a;
$disable-gray: #21252966;

$root-font-size: 16;

@function rem($pixels, $context: $root-font-size) {
  @return ($pixels/$context) * 1rem;
}

$touchpoint-modal-padding-right: rem(37);
$thin-divider: 1px solid $border-gray;

// bootstrap overrides

// $badge-font-size: rem(13);
$badge-pill-padding-x: 0.8em;
$body-color: $black;
$font-family-base: 'Nunito Sans', sans-serif;
$border-color: $border-gray; // $gray-300 !default;
$border-radius: rem(6);
$btn-border-radius: rem(30);
$btn-border-radius-sm: rem(30);
// $btn-border-width: 0;
$btn-font-size: rem(15);
$btn-font-weight: bold;
$btn-line-height: rem(19);
$btn-padding-y: rem(8.5);
$btn-padding-x: rem(25);
$btn-padding-x-sm: rem(16);
$card-border-radius: rem(9);
$card-border-width: 0;
$custom-select-bg: $light-gray-bg;
$custom-select-font-weight: normal;
$dropdown-color: $black;
$dropdown-link-active-bg: $goal-gray;
$dropdown-link-active-color: $black;
$dropdown-link-color: $black;
$dropdown-divider-bg: $border-gray;
$dropdown-divider-margin-y: 0;
$dropdown-item-padding-y: 1rem;
$dropdown-padding-y: 0;
$grid-gutter-width: 46px;
$main-gutter-width: 23px;
$headings-font-weight: 400;
$headings-margin-bottom: 0;
$h1-font-size: rem(29);
$h2-font-size: rem(26);
$h3-font-size: rem(24);
$h4-font-size: rem(21);
$h5-font-size: rem(18);
$h6-font-size: rem(16);
$input-border-color: $border-gray; // $gray-400 !default;
$input-border-radius: rem(6);
$input-color: $black;
// $input-font-weight: 700;
$input-placeholder-color: $playbook-pewter; // default: gray-600
$list-group-color: $black;
$list-group-border-color: $border-gray;
$list-group-action-color: $black;
// $list-group-item-padding-y
$modal-backdrop-opacity: 0.2;
$modal-content-border-radius: rem(13);
$modal-content-border-width: 0;
$modal-dialog-margin-y-sm-up: 5rem;
$modal-md: 670px;
$modal-xl: 976px;
$modal-header-padding-x: rem(25);
$modal-header-padding-y: rem(23);
$modal-inner-padding: rem(25);
$modal-small-padding-x: 54px;
$navbar-light-color: $black;
$navbar-light-hover-color: $action-anchor;
$nav-tabs-border-color: $border-color;
$progress-border-radius: rem(10);

$theme-colors: (
  primary: $schedule-sapphire,
  secondary: $implement-iron,
  light: $light-gray-bg,
  danger: $danger,
  success: $success,
  anchor: $action-anchor,
  white: $white
);