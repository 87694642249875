.loading-spinner {
  font-size: 40px;
}

.loading-container {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(207, 207, 207, .6);
  color: grey;
  z-index: 99999;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}