@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');
@import 'Theme';
@import '~bootstrap/scss/bootstrap';
@import 'Layout';
@import 'Animations';
@import 'Toast';
@import 'ReactSelect';
@import 'Hamburger/Hamburger.scss';
@import 'TinyMce';
// @import 'flatpickr/dist/themes/airbnb.css';
// @import 'flatpickr/dist/themes/light.css';
// @import "flatpickr/dist/themes/material_green.css";
@import 'flatpickr/dist/flatpickr.css';

// body {
//     font-family: 'Nunito Sans', sans-serif;
// }

html,
body,
#root,
.app,
.app-body,
.main {
    height: 100%;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.navbar-brand img {
    max-width: 130px;
    height: 49px;
    object-fit: contain;

    @include media-breakpoint-up(lg) {
        max-width: 181px;
    }

    &.icon {
        width: 40px;
    }
}

.navbar-toggler {
    border: 0;

    &:focus {
        outline: none;
    }
}

.card-footer {
    font-size: rem(13);
}

.btn {
    border-color: transparent;
    border-width: 0;
}

.btn-primary {
    background: $scope-sky;

    &:hover:enabled {
        background: $scope-sky-hover;
    }

    &:disabled {
        opacity: 40% !important;
    }
}

.btn-white {
    &:disabled {
        color: $disable-gray !important;
    }
}

.btn:not(.btn-light):not(.btn-link):not(.btn-outline-light) .svg-inline--fa {
    color: white;
}

.btn-light .svg-inline--fa {
    color: $icon-light-gray;
}

.btn-light {
    font-weight: normal;
}

.btn-outline-light {
    color: $gray-900;
    border-color: $gray-200;
    border-width: 1px;
    font-weight: normal;

    .svg-inline--fa {
        color: $schedule-sapphire;
    }
}

.btn-link {
    .svg-inline--fa {
        color: $schedule-sapphire;
    }
}

.btn-grey {
    background: $light-gray-bg;
    color: $playbook-pewter;
    border-color: $light-gray-bg;
    border-width: 1px;
    font-weight: normal;

    .svg-inline--fa {
        color: $playbook-pewter;
    }
}

.btn.filter {
    border-radius: rem(6);
    min-width: 8rem;
}

.btn-transparent {
    background-color: transparent;
    font-size: 14px;
    font-weight: 700;

    &:hover {
        color: $schedule-sapphire;
    }

    &:focus {
        outline: none !important;
        outline-offset: none !important;
    }
}

a {
    &.muted {
        color: $playbook-pewter;
    }
}

.text-align-start {
    text-align: start;
}

// .black-link {
//     &:hover {
//         color: $action-anchor;
//     }
// }

.extend-to-edge {
    margin-right: -$main-gutter-width;
}

.extend-to-edges {
    margin-left: -$main-gutter-width;
    margin-right: -$main-gutter-width;
}

// adding these to directly to bootstraps $spacing increases css size by ~6%
// (but obviously gives all the variations)
// $spacer: 1rem;
// $spacers: (
//     "1-5": ($spacer * .375),
//     "2-5": ($spacer * .75),
//     "4-5": ($spacer * 2.25));

.mb-0 {
    margin-bottom: 0rem !important;
}

.mb-1-5 {
    // 6px
    margin-bottom: 0.375rem;
}

.mr-1-5,
.mx-1-5 {
    // 6px
    margin-right: 0.375rem;
}

.ml-1-5,
.mx-1-5 {
    // 6px
    margin-left: 0.375rem;
}

@include media-breakpoint-up(md) {
    .ml-md-1-5 {
        // 6px
        margin-left: 0.375rem;
    }

    .mr-md-2-5 {
        margin-right: 0.75rem;
    }
}

.mt-2-5,
.my-2-5 {
    // 12px
    margin-top: 0.75rem;
}

.mb-2-5,
.my-2-5 {
    // 12px
    margin-bottom: 0.75rem;
}

.mr-2-5 {
    // 12px
    margin-right: 0.75rem;
}

.ml-2-5 {
    // 12px
    margin-left: 0.75rem;
}

.ml-3-5,
.mx-3-5 {
    // 20px
    margin-left: 1.25rem;
}

.mr-3-5,
.mx-3-5 {
    // 20px
    margin-right: 1.25rem;
}

.mt-4-5,
.m-4-5,
.my-4-5 {
    // 36px
    margin-top: 2.25rem !important;
}

.mb-4-5,
.m-4-5,
.my-4-5 {
    // 36px
    margin-bottom: 2.25rem !important;
}

.mr-4-5,
.m-4-5,
.mx-4-5 {
    // 36px
    margin-right: 2.25rem !important;
}

.ml-4-5,
.m-4-5,
.mx-4-5 {
    // 36px
    margin-left: 2.25rem !important;
}

.mb-10 {
    margin-bottom: 10rem;
}

.pt-2-5,
.py-2-5 {
    // 12px
    padding-top: 0.75rem !important;
}

.pb-2-5,
.py-2-5 {
    // 12px
    padding-bottom: 0.75rem !important;
}

.pt-4-3 {
    // 32px
    padding-top: 2rem !important;
}

// @each $color,
// $values in ("scope-sky": ($scope-sky-1, $scope-sky-2), "join-jade": ($join-jade-1, $join-jade-2)) {
//     .color-#{$color} {
//         color: $value;
//     }
// }

// .svg-settings {
//     /* Hiding this content since it's just settings. See - https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
//     position: absolute !important;
//     height: 1px;
//     width: 1px;
//     overflow: hidden;
//     clip: rect(1px, 1px, 1px, 1px);
// }

@each $color,
$values in ('scope-sky': ($scope-sky-1,
        $scope-sky-2,
    ),
    'join-jade': ($join-jade-1,
        $join-jade-2,
    ),
    'process-peach': ($process-peach-1,
        $process-peach-2,
    ),
    'retention-rose': ($retention-rose-1,
        $retention-rose-2,
    )) {
    // .fill-gradient-linear.#{$color} path {
    //     fill: url(##{$color});
    // }

    // .#{$color}-stop1 {
    //     stop-color: nth($values, 1);
    // }

    // .#{$color}-stop2 {
    //     stop-color: nth($values, 2);
    // }

    .#{$color} {
        background: linear-gradient(90deg, nth($values, 1) 0%, nth($values, 2) 100%);
    }
}

.nav-link {
    color: $black;
    font-weight: normal;
    text-decoration: none;

    &:hover,
    &:hover .svg-inline--fa {
        color: $action-anchor;
    }
}

.nav-link-light {
    color: $playbook-pewter;
    font-size: rem(14);
    font-weight: bold;
}

.small-info {
    color: $playbook-pewter;
    font-size: rem(14);
}

.small-danger {
    color: $danger;
    font-size: rem(14);
}

.smaller-info {
    color: $playbook-pewter;
    font-size: rem(11);
}

.activity-icon-wrapper {
    width: rem(43);
}

@include media-breakpoint-down(md) {
    .navbar .nav-link {
        padding: 0;
    }
}

.nav-tabs {
    padding-left: rem(14);

    .nav-link {
        color: $playbook-pewter;

        &:not(.active):focus,
        &:not(.active):hover {
            border-color: transparent;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        font-weight: bold;
    }
}

.tab-pane {
    margin-top: rem(20);
}

.svg-inline--fa {
    color: $implement-iron;

    &.close-button {
        @include media-breakpoint-up(md) {
            margin-right: $touchpoint-modal-padding-right;
        }
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            color: $action-anchor;
        }
    }
}

.tag-edit-input {
    @include media-breakpoint-up(md) {
        width: 25%;
    }
}

.tag-delete-icon {
    &.svg-inline--fa {
        font-size: rem(14);
    }
}

.stop-editing-tags-button {
    .svg-inline--fa {
        font-size: rem(14);
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.dropdown-divider {
    margin-left: 1.5rem;
}

.dropdown-item .svg-inline--fa {
    margin-right: 0.75rem;
}

.dropdown-item.danger,
.dropdown-item.danger .svg-inline--fa {
    color: $danger;
}

span.danger {
    color: $danger;
}

.navbar-nav .dropdown-menu {
    // by default bootstrap makes the dropdown menu not absolute on smaller screens
    position: absolute;
}

.full-screen-with-left-content {
    background: $scope-sky;
    min-height: 100vh;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.625rem 2rem;
    place-items: center;
    gap: 2rem;

    a.muted {
        font-size: rem(14);
    }

    @media only screen and (max-width: 900px) {
        grid-template-columns: 1fr;

        .left-content {
            padding-inline: rem(140);
        }

        .right-content {
            grid-row: 1;
        }
    }

    @media only screen and (max-width: 768px) {
        .left-content {
            padding-inline: rem(76);
        }
    }

    @media only screen and (max-width: 600px) {
        .left-content {
            padding-inline: unset;
        }
    }
}

.full-screen-with-background {
    background: $scope-sky;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    a.muted {
        font-size: rem(14);
    }
}

.avatar-circle {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(12);
    border-radius: 50%;
    width: rem(36);
    height: rem(36);
    background-size: cover;
    background-color: $action-anchor;
    font-weight: 800;
    flex-shrink: 0;

    &.light {
        background-color: $circle-icon-gray;

        .svg-inline--fa {
            color: $playbook-pewter;
        }
    }

    &.small {
        font-size: rem(9);
        width: rem(27);
        height: rem(27);
        padding-top: rem(3);
    }
}

.page-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    position: sticky;
    left: 0;
    z-index: 2;

    .svg-inline--fa.fa-angle-left {
        color: $schedule-sapphire;
    }
}

.page-header.with-buttons {
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: flex-end;
    }
}

.page-header-col {
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

h2,
.nav-title {
    color: $playbook-pewter;
    font-weight: bold;
    font-size: rem(14);
}

.settings-section-title {
    color: $playbook-pewter;
    margin-top: 2.75rem;
    margin-bottom: 1rem;

    &.top {
        margin-top: 0.75rem;
    }

    &.custom-fields {
        margin-bottom: 0.25rem;
    }
}

.settings-survey-fixed-col {
    font-weight: normal;
    width: 80px;
    display: flex;
    justify-content: center;

    input[type='number'] {
        width: 65px;
        font-weight: normal;
        text-align: center;
    }
}

.settings-survey-divider {
    margin: 0.65rem 0;
}

.settings-survey-fixed-col.alerts {
    width: 60px;
    justify-content: flex-end;
}

.row .settings-left-section {
    @include media-breakpoint-up(md) {
        padding-right: rem(40);
    }
}

.settings-right-section {
    background: $standard-snow;
    border-bottom-right-radius: 0.8125rem;

    @include media-breakpoint-down(sm) {
        margin-top: 2rem;
        padding-bottom: 2rem;
        border-top: $thin-divider;
    }

    @include media-breakpoint-up(md) {
        border-left: $thin-divider;
        // min-height: calc(100vh - 200px);
    }
}

.settings-logo-placeholder {
    background-color: $goal-gray;
    position: relative;
    width: 100%;
    height: 0;
    /* This is your aspect ratio */
    padding-top: 58.7%;

    .inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        color: $playbook-pewter;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 1rem;
    }
}

.settings-row {
    margin-bottom: 1.25rem;
    max-width: 30.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
        display: flex;
        align-items: center;
    }
}

.ideas-section {
    .header {
        color: $black;
        font-weight: bold;
        font-size: rem(16);
    }

    .likes {
        color: $black;
        font-weight: bold;
        font-size: rem(14);
    }

    .buttons {
        color: $playbook-pewter;
        font-size: rem(14);
        justify-content: flex-end;

        @include media-breakpoint-down(sm) {
            justify-content: start;
        }

        .svg-inline--fa {
            color: $playbook-pewter;
            font-size: rem(14);
        }

        .fa-thumbs-up.highlight {
            color: $scope-sky-2;
            cursor: pointer;

            &:hover {
                color: $scope-sky-2-hover;
            }
        }
    }
}

.table thead th {
    border-bottom: inherit;
}

.table th {
    border-top: 0;
}

.table th:first-child,
.table td:first-child {
    padding-left: 0;
}

.table th:last-child,
.table td:last-child {
    padding-right: 0;
}

.table th,
.table-header {
    color: $playbook-pewter;
    font-size: rem(14);
    font-weight: normal;
}

.canvas-content-wrapper {
    display: flex;
    align-items: center;
}

.tags-days-wrapper {
    display: flex;
    align-items: baseline;
}

.days-subheader {
    color: $playbook-pewter;
    font-size: rem(14);
    font-weight: normal;
    vertical-align: bottom;
}

.table tbody tr:hover {
    background-color: $hover;
    cursor: pointer;
}

.table-header {
    margin-bottom: rem(8);
}

.dot {
    display: inline-block;
    border-radius: 50%;
    width: rem(7);
    height: rem(7);
    background-color: $danger;
    margin-right: 0.75rem;
}

.nav-dot {
    position: absolute;
    top: rem(-7);
    right: rem(-7);
    width: rem(12);
    height: rem(12);
    background-color: white;
    border-radius: 50%;
    transition: opacity 0.25s ease-in-out 0s;

    div {
        border-radius: 50%;
        width: rem(8);
        height: rem(8);
        background-color: $danger;
        margin: rem(2);
    }
}

.table-row-outer {
    cursor: pointer;
    display: block;
    color: initial;
    font-weight: initial;
    padding: 0 ($grid-gutter-width / 2);
    margin: 0 (-$grid-gutter-width / 2);

    &.modal-small {
        padding: 0 $modal-small-padding-x;
        margin: 0 (-$modal-small-padding-x);
    }

    &:not(.no-hover):hover {
        color: initial;
        text-decoration: initial;
        background: $hover;
    }

    &.no-hover {
        cursor: initial;
    }
}

.table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
    // this causes the bottom border to extend to the edge on extra small
    // padding-right: $grid-gutter-width / 2;
    // margin-right: -$grid-gutter-width / 2;

    &:not(.no-border) {
        border-top: $thin-divider;
    }

    &.smaller {
        padding: 1rem 0;
    }

    @include media-breakpoint-up(sm) {
        padding-right: 0;
        margin-right: 0;
    }

    .bold {
        font-weight: $font-weight-bold;
    }

    .row {
        align-items: center;

        @at-root .table-row-outer.align-top & {
            align-items: flex-start;
        }

        flex-grow: 1;
    }

    &.no-pointer {
        cursor: default;
    }
}

.clickable-icon,
.fa-comment.light,
.fa-pencil.light,
.fa-thumbs-up.light,
.fa-clone,
.fa-times:not(.close-button) {
    color: $playbook-pewter;
    cursor: pointer;

    &:hover {
        color: $action-anchor;
    }
}

.fa-times {
    font-size: rem(26);
}

.trash-col,
.last-col {
    min-width: $grid-gutter-width + 14px;
    flex-grow: 0;

    .svg-inline--fa {
        font-size: 14px;
    }
}

.profile-icon {
    color: $goal-gray;
}

.members-row-user-icon {
    color: $goal-gray;
    font-size: 2.25rem;
    margin-right: 0.75rem;

    @include media-breakpoint-up(sm) {
        font-size: 3.375rem;
        margin-right: 1.5rem;
    }
}

@for $i from 1 through 9 {
    $zIndexBackdrop: #{$zindex-modal + (5 * $i)};
    $zIndexContent: #{$zindex-modal + (5 * $i) + 2};

    .modal-backdrop.show:nth-of-type(#{$i}) {
        z-index: $zIndexBackdrop;
    }

    .modal:nth-of-type(#{$i}) {
        z-index: $zIndexContent;
    }
}

.modal-content {
    .modal-body {
        padding-top: 0;
    }

    .modal-header {
        display: grid;
        gap: 0.75rem;
        grid-template-columns: 1fr;
        align-items: center;

        @include media-breakpoint-up(sm) {
            grid-template-columns: 1fr auto;
        }

        .full-width-grid-item {
            grid-column: -1/1;
        }
    }
}

.modal-small .modal-dialog {
    max-width: 528px;

    .modal-body {
        padding: 0px $modal-small-padding-x 20px;
    }
}

.modal-large-padding {
    // .modal-header {
    //     padding-top: 45px;
    //     justify-content: center;
    // }

    .wrapper {
        display: flex;
        justify-content: center;

        .inner {
            width: 100%;
            // max-width: 520px;
            // padding: 40px 20px;

            @include media-breakpoint-up(sm) {
                padding: 80px 20px;
            }
        }
    }

    .buttons {
        padding-top: 40px;

        .auto {
            max-width: unset !important;
        }

        .btn {
            @include media-breakpoint-up(sm) {
                max-width: 232px;
            }
        }
    }
}

.modal-full {
    .modal-content {
        // height: 100vh;
        padding: $main-gutter-width $main-gutter-width 0 $main-gutter-width;

        @include media-breakpoint-up(md) {
            padding: 30px 0 0 45px;

            .tab-content .tab-pane:not(#touchpoint-tabs-tabpane-step) {
                padding-right: $touchpoint-modal-padding-right;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .modal-dialog {
            max-width: 976px;
            //   margin: $modal-dialog-margin-y-sm-up auto;
        }
    }
}

.list-group-item {
    padding: $list-group-item-padding-y $list-group-item-padding-x $list-group-item-padding-y 0;
}

// try to undo webkit autofill styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill::first-line {
    font-family: $font-family-base;
    font-size: 1rem;
    font-weight: $input-font-weight;
    color: $body-color;
}

.form-control::placeholder {
    font-weight: 400;
}

.form-control::-webkit-input-placeholder {
    font-weight: 400;
}

.form-control::-moz-placeholder {
    font-weight: 400;
}

.form-control::-ms-input-placeholder {
    font-weight: 400;
}

.no-border {
    border: none;
}

.grey-form-control {
    background-color: $circle-icon-gray;
    border: none;
}

.reminder-date-input {
    max-width: 240px;
    font-weight: normal;
}

.date-picker {
    position: relative;

    .input {
        cursor: pointer;
    }

    .svg-inline--fa {
        position: absolute;
        pointer-events: none;
        top: 10px;
        right: 10px;
        color: $schedule-sapphire;
    }
}

.form_submitted input.error {
    border-color: var(--danger);
}

.circle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    width: rem(32);
    height: rem(32);
    // margin-right: rem(12);
    font-size: rem(14);
    color: white;
    background-color: #eef0f1;

    &.check {
        width: rem(21);
        height: rem(21);
        background-color: white;
        border: 1px solid $playbook-pewter;
        font-size: rem(11);

        &.large {
            width: rem(36);
            height: rem(36);
            font-size: rem(16);
            background-color: $circle-icon-gray;
            border-color: $circle-icon-gray;
        }

        &.step-toggle {
            background-color: white;
            border-color: $schedule-sapphire;
            box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
            top: rem(-18);
            right: rem(-18);
        }

        .svg-inline--fa {
            color: $playbook-pewter;
            margin: 0;
        }

        &.complete {
            background-color: #00c099;
            border-color: #00c099;

            .svg-inline--fa {
                color: white;
            }
        }

        &.selected {
            background-color: $scope-sky-2;
            border-color: $scope-sky-2;

            .svg-inline--fa {
                color: white;
            }
        }
    }
}

input.editable-header,
textarea.resizable-textarea {
    font-size: inherit;
    font-weight: inherit;
    resize: none;
    border: none;

    &:hover:not(:focus):not(:disabled) {
        box-shadow: 0 0 0 1px $border-gray;
    }

    &:not(textarea.resizable-textarea) {
        margin-left: -0.75rem;
    }

    &:disabled {
        background: #fff;
    }
}

.checklist-item {
    &:disabled {
        background: #fff;
    }
}

.toggle-edit {
    width: 182px;

    .toggle-edit-nonedit {
        overflow: hidden;
        height: 60px;
    }

    textarea.editable-header {
        font-size: inherit;
        font-weight: inherit;

        &:hover:not(:focus) {
            box-shadow: 0 0 0 1px $border-gray;
        }

        margin-left: -0.75rem;
        border: none;
        resize: none;
        width: auto;
    }
}

input.editable-header.light {
    color: $playbook-pewter;
    font-weight: bold;
    font-size: rem(14);
}

.modal-section-header {
    color: $playbook-pewter;
    font-weight: bold;
    font-size: rem(14);
}

.tab-title-small {
    font-size: rem(11);
}

.switch-row {
    display: flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    justify-content: space-between;
}

.top-border {
    border-top: $thin-divider;
}

.bottom-border {
    border-bottom: $thin-divider;
}

.tag-selection-color {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(32);
    height: rem(32);
    font-size: rem(14);
    border-radius: 50%;

    .svg-inline--fa {
        color: white;
    }
}

.canvas-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    width: rem(90);
    height: rem(68);
    margin-right: rem(8);
    font-size: rem(14);
    color: white;
}

.table-rows-wrapper {
    margin-left: $main-gutter-width;
    margin-right: $main-gutter-width;
}

.date {
    color: $action-anchor;
    font-size: rem(11);
    font-weight: bold;
    white-space: nowrap;

    &.past-due {

        &,
        .svg-inline--fa {
            color: var(--orange-bright);
        }
    }

    &.past-due-purple {

        &,
        .svg-inline--fa {
            color: var(--purple-bright);
        }
    }
}

.past-due {
    color: var(--orange-bright) !important;
}

.status-on-time {
    color: var(--schedule-sapphire-focus) !important
}

.status-completed {
    color: var(--green-bright) !important
}

.status-paused {
    color: var(--champion-charcoal) !important
}

.due-date-small {
    color: $action-anchor;
    font-size: 12px;
    font-weight: bold;
}

.columns-wrapper {
    margin-left: -$main-gutter-width;
    margin-right: -$main-gutter-width;

    .columns {
        display: flex;
        // padding-left: $main-gutter-width;
        // padding-right: $main-gutter-width;
        scroll-snap-type: x mandatory;

        &.dragging {
            scroll-snap-type: none;
        }

        .card-column-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: unset !important;
            margin-bottom: 21px;
            margin-top: 2px;

            .title {
                color: $champion-charcoal;
            }

            .svg-inline--fa {
                color: $playbook-pewter;

                &:hover {
                    color: darken($playbook-pewter, 10%);
                }

                font-size: rem(14);
            }

            h2 {
                word-break: break-word;
            }
        }

        .card-width {
            background: $circle-icon-gray;
            color: $champion-charcoal;

            span {
                font-weight: 700;
                font-size: 14px;
            }

            &:hover:not(.no-click) {
                background-color: $goal-gray;
            }
        }

        .card-width,
        .card-column-header {
            width: calc(100vw - #{rem(48)} - #{$main-gutter-width * 2});

            @include media-breakpoint-up(sm) {
                width: 240px;
            }
        }

        .column {
            scroll-snap-align: start;
            padding-left: $main-gutter-width;

            @include media-breakpoint-up(xs) {
                margin-right: rem(10);
                min-width: 303px;
            }

            &:last-child {
                padding-right: rem(26);
            }

            .card-wrapper {
                display: flex;
                align-items: flex-start;
                padding-bottom: rem(24);
                // margin-bottom: rem(24);
                position: relative;

                .circle-icon {
                    margin-right: rem(8);
                }

                button {

                    .fa-plus,
                    .fa-check {
                        color: $playbook-pewter;
                    }
                }

                &:not(:last-child).connected {
                    .circle-icon:not(.step-toggle) {
                        &::before {
                            content: '';
                            position: absolute;
                            background-image: linear-gradient($icon-light-gray 33%,
                                    rgba(255, 255, 255, 0) 0%);
                            background-position: right;
                            background-size: 2px 8px;
                            background-repeat: repeat-y;
                            width: 2px;
                            height: 100%;
                            bottom: -2rem;
                        }
                    }
                }

                .card-content {
                    color: $black;
                    background-color: $circle-icon-gray;
                    border-radius: rem(5);
                    padding: 12px 12px 16px 12px;
                    font-weight: inherit;
                    word-break: break-word;

                    &.complete {
                        color: $playbook-pewter;
                        opacity: 0.5;
                    }

                    &:hover:not(.no-click) {
                        text-decoration: none;
                        background-color: $goal-gray;
                        cursor: pointer;
                    }

                    .input-card {
                        background-color: $circle-icon-gray;
                        border-color: $circle-icon-gray;
                    }

                    .header {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                    }

                    .card-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 8px;

                        .svg-inline--fa {
                            color: $playbook-pewter;
                            font-size: rem(14);

                            &:hover {
                                color: darken($playbook-pewter, 10%);
                            }
                        }
                    }

                    .small-text {
                        color: $action-anchor;
                        font-size: rem(13);
                    }

                    .large-text {
                        font-weight: bold;
                        margin-top: rem(4);
                    }

                    .project-late {
                        line-height: 1.8;
                    }

                    .tags {
                        margin-top: 20px;
                        margin-bottom: 6px;
                    }

                    .card-icons {
                        .svg-inline--fa {
                            color: $icon-light-gray;
                        }
                    }
                }
            }
        }
    }
}

.tag-builder {
    max-width: rem(296);

    .tag-edit-input {
        width: 100%;
    }

    .buttons {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 10px;
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;

    .tag {
        border-radius: 30px;
        border: 1px solid black;
        background-color: white;
        padding: rem(0) rem(7);
        font-size: rem(11);
        font-weight: normal;
        margin: 0 6px 6px 0;
        display: flex;
        align-items: center;

        .svg-inline--fa {
            margin: 4px 0px 4px 10px;
            color: $icon-light-gray;
            font-size: 1em;

            &:hover {
                color: darken($icon-light-gray, 10%);
            }
        }

        @include media-breakpoint-up(md) {
            &.large {
                font-size: rem(14);
                padding: rem(6.5) rem(20);
                margin: 0 rem(10) rem(10) 0;
            }
        }
    }
}

.empty-tag {
    border-color: $circle-icon-gray;
    color: $playbook-pewter;
}

.all-or-my-select {
    width: initial;

    @include media-breakpoint-up(sm) {
        width: rem(212);
    }
}

.canvas-page-header {
    align-items: start;
    flex-wrap: nowrap;
    flex-direction: row;
    position: sticky;
    left: 0;
    z-index: 2;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;

        // title
        &>div:first-child {
            flex: 0 1 80%;
        }

        .canvas-overflow {
            flex: 0 1 20%;
            text-align: right;
        }
    }
}

.builder-header {
    display: grid;
    gap: rem(30);
    grid-template-columns: repeat(2, auto);
    margin-bottom: 0.8rem;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(3, auto);
        column-gap: rem(50);
    }

    .section {
        .small-info {
            color: $champion-charcoal;
        }
    }
}

.canvas-header {
    display: grid;
    gap: rem(20);
    grid-template-columns: repeat(2, auto);
    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-down(sm) {
        margin-top: rem(20);
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        margin-left: auto;
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 1fr 0.8fr 1.2fr;
        gap: rem(30);
        flex: 0 0 70%;
    }

    .section {
        .col {
            padding: 0;

            .sub-title {
                line-height: 1.8;
                padding-right: 7px;
                text-align: right;
                font-weight: 400;
            }
        }

        .title {
            margin-bottom: rem(6);
            padding-right: 7px;
        }

        .content {
            color: $black;
            font-weight: bold;
            font-size: rem(14);

            .content-label {
                color: $playbook-pewter;
                display: inline-block;
                font-weight: normal;
                min-width: rem(46);

                &.large {
                    min-width: rem(69);
                }
            }
        }
    }
}

.touchpoint-modal-header {
    display: grid;
    gap: 2.25rem;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(sm) {
        grid-template-columns: minmax(12.5rem, 15.5rem) minmax(12.5rem, auto);
    }
}

.members-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.375rem;
    flex-shrink: 0;
    max-width: 16.125rem;
}

.progress {
    height: 6px;

    .progress-bar {
        background: $scope-sky;
        border-radius: rem(20);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    /* Firefox */
    -moz-appearance: textfield;
}

.mentions-wrapper {
    margin-bottom: 0.75rem;
    min-height: 5em;

    .suggestions {
        padding: 0.4rem !important;
        margin-top: 28px !important;
        left: 10px !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        min-width: 160px !important;
    }

    .item-focused {
        background: #ccc;
    }
}

.negative-gutter-md-downs {
    @include media-breakpoint-down(md) {
        margin-left: -$main-gutter-width;
        margin-right: -$main-gutter-width;
    }
}

.color-black {
    color: $black;
}

.color-sapphire {
    color: $schedule-sapphire;
}

.color-icon-light-gray {
    color: $icon-light-gray;
}

.color-action-anchor {
    color: $action-anchor;
}

.color-completed {
    color: $playbook-pewter;
}

// .deletable-resource {
//     .fa-trash {
//         visibility: hidden;
//     }

//     &:hover {
//         .fa-trash {
//             visibility: initial;
//         }
//     }
// }

.assigning-to-box {
    border-radius: rem(5);
    padding: rem(24) rem(30);
    box-shadow: 0 rem(5) rem(30) 0 rgba(0, 0, 0, 0.08);
}

.canvas-item {
    display: flex;
    align-items: center;

    &.disabled {
        // V2
        color: $playbook-pewter;

        .canvas-icon {
            opacity: 33%;
        }
    }

    .eyebrow-text {
        // V2
        color: $playbook-pewter;
        font-size: rem(14);
    }

    .canvas-name {
        font-weight: bold;
    }
}

.customer-details-form {
    display: grid;
    column-gap: rem(56);
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-flow: row;

    .section {
        margin-bottom: rem(38);

        h2 {
            margin-bottom: rem(13);
        }
    }
}

.items-gap {
    grid-gap: 0.375em;
}

.gmail-connection-profile {
    background: $gray-100;
    padding: 15px;
    border-radius: 0.375rem;
}

.z-inc {
    z-index: 1;
}

.z-dec {
    z-index: -1;
}

.pl-48 {
    padding-left: 3rem;
}

.w-full {
    width: 100% !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.fs-4 {
    font-size: 1.5rem;
}

.fs-5 {
    font-size: 1.25rem;
}

.fs-6 {
    font-size: 1rem;
}

.fs-7 {
    font-size: 0.875rem;
}

.filter-header {
    font-size: 11px;
    font-weight: 700;
    color: #54819f;
}

.inactive-account {
    opacity: 0.8;
    color: $playbook-pewter;
}

.Toastify__toast-container--bottom-right {
    width: 25%;
    font-size: 14px;
    min-height: 35px;
    margin-right: 16px;
}

.bg-white {
    background-color: $white;
}

.custom-field {
    &__header {
        display: flex;
        align-items: center;
        gap: 1rem;

        &>label {
            margin-bottom: 0;
        }
    }

    &.date-time .form-control:disabled,
    &.date-time .form-control[readonly] {
        background-color: var(--light-gray-bg);
    }

    &.read-only,
    &.default-field {

        input,
        textarea,
        .select__placeholder,
        .select__single-value {
            color: var(--playbook-pewter);
        }

        input,
        textarea {
            background-color: var(--white);
        }

        .form-check>input {
            opacity: 80%;
        }

        .form-check>label {
            color: var(--champion-charcoal);
        }

        .custom-field__input {
            svg {
                opacity: 30%;
            }
        }
    }

    &:not(.read-only) {
        .custom-field__input {
            svg {
                opacity: 1;
            }
        }
    }

    &.preview {

        .grip,
        .custom-field__options {
            display: none;
        }

        .custom-field__input {
            margin-left: 0;
        }

        .custom-field__input.custom-detail-field {

            input::placeholder,
            textarea::placeholder,
            .react-select__placeholder {
                color: var(--champion-charcoal);
            }
        }
    }

    &.preview.read-only {
        .custom-field__input.custom-detail-field {

            input:disabled,
            input::placeholder,
            textarea:disabled,
            textarea::placeholder {
                background-color: var(--white);
                color: var(--playbook-pewter);
            }
        }
    }

    .fa-lock-keyhole {
        color: var(--playbook-pewter);
    }

    .detail-field-lock {
        color: var(--implement-iron);
    }

    &__permission {
        font-size: 0.75rem;
        font-weight: 400;
    }

    &__options {
        display: flex;
        align-items: center;
        margin-left: auto;

        .lock-container,
        .eye-container {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        .hidden-block {
            position: absolute;
            height: 0.875rem;
            width: 0.875rem;
            background: transparent;
            top: 1rem;
        }

        .icon-container {
            display: flex;
            position: relative;
        }

        .tooltip-action {
            font-size: 1rem;
            font-weight: bold;
            color: var(--schedule-sapphire);
            margin-right: 0;
        }

        span {
            cursor: pointer;
        }

        span,
        svg:not(:last-child) {
            margin-right: 0.875rem;
        }

        p {
            margin: 0;

            span {
                font-weight: bold;
            }
        }
    }

    &__input {
        &:not(.user) {
            margin: 0.5rem 0 0 1.5rem;
        }

        .react-select--is-disabled {
            svg {
                color: var(--outline);
            }
        }

        &.custom-detail-field {

            textarea:disabled,
            input:disabled {
                background-color: var(--white);
                color: var(--playbook-pewter);
            }
        }
    }
}

.server-error-toast {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        font-weight: 700;
    }

    p {
        margin: 0;
    }
}

.icon-implement-iron {
    color: var(--implement-iron) !important;
}

.text-xs {
    font-size: rem(10) !important;
}