.react-select-container {

    .react-select__placeholder {
        color: $input-placeholder-color;
        font-weight: normal;
    }

    .react-select__control {
        border-color: $border-gray;
        border-radius: $input-border-radius;
        font-weight: $input-font-weight;
        color: $input-color;

        &:hover {
            border-color: $border-gray;
        }
    }

    .react-select__menu {
        z-index: 2;
    }

    .react-select__value-container {
        padding-left: 10px;
    }


    .react-select__option--is-focused,
    .react-select__option--is-focused:active {
        background-color: $hover;
    }

    .react-select__option--is-selected {
        background-color: $schedule-sapphire;
    }

    .react-select__input input {
        font-weight: $input-font-weight;
    }

    .react-select__single-value {
        color: $input-color;
    }
}